<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="text-center">
          <div>
            <span class="logo">
              <img
                src="@/assets/images/logo/logo-jogos-mazzarello.png"
                height="300"
                alt="logo"
              />
            </span>
          </div>
        </div>
        <h1 class="text-center">Portal de Inscrições</h1>
        <h6 class="text-center">Agência de Marketing Go I</h6>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'

export default {
  name: 'Home',

  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      title: 'Home',
      items: [
        {
          text: 'Home',
          active: true,
        },
      ],
    }
  },
}
</script>
